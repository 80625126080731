import React from "react";
import ButtonTransparent from "../ButtonTransparent";
import ContainerTransparent from "../ContainerTransparent";
import HyperLinkButton from "../HyperlinkButton";
import FadeAnimation from "../../animations/Fade";

export default function AssetIdTokenPage() {
  return (
    <div className="flex xs:flex-col xs:space-y-4 sm:flex-col sm:space-y-4 md:flex-col md:space-y-4 lg:flex-row xl:flex-row 2xl:flex-row mt-16 w-full space-x-2">
      <div className="xs:w-full sm:w-full md:w-full lg:w-2/6 xl:w-2/6 2xl:w-2/6">
        <ContainerTransparent borderColor={"#1cffd6"}>
          <FadeAnimation direction={"bottomToTop"} duration={1}>
            <h3 className="text-accentColor my-2">Available on:</h3>
            <div className="flex flex-wrap justify-between gap-4 w-full xs:flex-col xs:space-y-2 sm:flex-row  md:flex-row lg:flex-row xl:flex-row 2xl:flex-row">
              <div>
                <ButtonTransparent
                  title={"Humble Swap"}
                  url={"https://app.humble.sh/swap"}
                  displayIcon={false}
                  showBorder={true}
                />
              </div>
              <div>
                <ButtonTransparent
                  title={"Folks Finance"}
                  url={"https://app.folks.finance"}
                  displayIcon={false}
                  showBorder={true}
                />
              </div>
              <div>
                <ButtonTransparent
                  title={"Pact"}
                  url={"https://app.pact.fi/swap"}
                  displayIcon={false}
                  showBorder={true}
                />
              </div>
              <div>
                {" "}
                <ButtonTransparent
                  title={"Tinyman"}
                  url={"https://app.tinyman.org/"}
                  displayIcon={false}
                  showBorder={true}
                />
              </div>
            </div>
          </FadeAnimation>
        </ContainerTransparent>
      </div>
      <div className="xs:w-full sm:w-full md:w-full lg:w-4/6 xl:w-4/6 2xl:w-4/6 data-tooltip-target=tooltip-default">
        <ContainerTransparent borderColor={"#1cffd6"}>
          <FadeAnimation direction={"bottomToTop"} duration={1}>
            <h3 className="text-accentColor py-2">Assets ID's:</h3>
            <div className="flex flex-wrap justify-between gap-4  content-center xs:flex-col xs:space-y-2 sm:flex-col sm:space-y-2">
              <HyperLinkButton
                title={"goBTC (ID: 386192725)"}
                url={"https://allo.info/asset/386192725"}
                color={"white"}
                borderColor={"white"}
              />
              <HyperLinkButton
                title={"goETH (ID: 386195940)"}
                url={"https://allo.info/asset/386195940"}
                color={"white"}
                borderColor={"white"}
              />
              <HyperLinkButton
                title={"goMINT (ID: 441139422)"}
                url={"https://allo.info/asset/441139422"}
                color={"white"}
                borderColor={"white"}
              />
              <HyperLinkButton
                title={"goUSD (ID: 672913181)"}
                url={"https://allo.info/asset/672913181"}
                color={"white"}
                borderColor={"white"}
              />
            </div>
          </FadeAnimation>
        </ContainerTransparent>
      </div>
    </div>
  );
}
