import Fade from "../../animations/Fade";
import React from "react";
import "./TokenPageParagraphBanner.css";
import { tokenParagraphItems } from "../../data/TokenParagraphItems";

export default function TokenPageParagraphBanner() {
  return (
    <>
      {tokenParagraphItems.map((data, index) => {
        return (
          <Fade direction={"bottomToTop"} duration={1} key={index}>
            <div className="mx-8">
              <h3 className="title my-8">{data.title}</h3>
              <p
                className="text-left"
                dangerouslySetInnerHTML={{ __html: `${data.content}` }}
              ></p>
            </div>
          </Fade>
        );
      })}
    </>
  );
}
