import React from "react";
import Layout from "../components/Layout";
import TokenLaunchComponent from "../components/TokenLaunchComponent";
import TokenLiveBanner from "../components/TokenLiveBanner";
import GoMintLiveBanner from "../components/GoMintNowLiveBanner";
import InvestorsTokenBanner from "../components/InvestorTokenBanner";
import TokenPageParagraphBanner from "../components/TokenPageParagraphBanner";
import AssetIdTokenPage from "../components/AssetIdTokenPage";
import { Helmet } from "react-helmet";

export default function TokenLaunch() {
  return (
    <div>
      <Layout>
        <Helmet>
          <title>Algomint | Token Launch</title>
          <script>
            {`
              !function(t,e){var o,n,p,r;e.__SV||(window.posthog=e,e._i=[],e.init=function(i,s,a){function g(t,e){var o=e.split(".");2==o.length&&(t=t[o[0]],e=o[1]),t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}}(p=t.createElement("script")).type="text/javascript",p.async=!0,p.src=s.api_host+"/static/array.js",(r=t.getElementsByTagName("script")[0]).parentNode.insertBefore(p,r);var u=e;for(void 0!==a?u=e[a]=[]:a="posthog",u.people=u.people||[],u.toString=function(t){var e="posthog";return"posthog"!==a&&(e+="."+a),t||(e+=" (stub)"),e},u.people.toString=function(){return u.toString(1)+".people (stub)"},o="capture identify alias people.set people.set_once set_config register register_once unregister opt_out_capturing has_opted_out_capturing opt_in_capturing reset isFeatureEnabled onFeatureFlags".split(" "),n=0;n<o.length;n++)g(u,o[n]);e._i.push([i,s,a])},e.__SV=1)}(document,window.posthog||[]);
              posthog.init('phc_igoJY4YFsqvSyr3ZG8RCPw8bzWMMtumGFJsylr0T9bz', {api_host: 'https://app.posthog.com'})
            `}     
          </script>
        </Helmet>
        <TokenLiveBanner />
        <TokenLaunchComponent />
        <GoMintLiveBanner />
        <InvestorsTokenBanner />
        <TokenPageParagraphBanner />
        <AssetIdTokenPage />
      </Layout>
    </div>
  );
}
