import "./ContainerTransparent.css";
import React from "react";

export default function containerTransparent({ children, borderColor }) {
  return (
    <div
      className={`containerTransparent-wrapper  ${
        borderColor ? `border-[${borderColor}]` : `border-[#e8e8e8]`
      }`}
    >
      {children}
    </div>
  );
}
