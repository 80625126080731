import React from "react";
import "./HyperLinkButton.css";
import { navigate } from "gatsby";
export default function hyperLinkButton({
  title,
  url,
  color,
  borderColor,
  navigateInApp,
}) {
  return (
    <div
      className="flex hyperlink-btn-wrapper cursor-pointer"
      onClick={() => {
        navigateInApp ? navigate(url) : window.open(url, "_blank");
      }}
    >
      <div
        className={`text-link-body xs:text-xs sm:text-sm md:text-md lg:text-md xl:text-md 2xl:text-md text-${
          color ? color : "accentColor"
        } text-${borderColor ? borderColor : "accentColor"}`}
      >
        {title}
      </div>
      <img
        src="/arrow-large-green.svg"
        loading="lazy"
        alt="arrow icon"
        className="link-arrow-icon"
      />
    </div>
  );
}
