import React from "react";
import "./GoMintLive.css";
import Fade from "../../animations/Fade";

export default function GoMintLiveBanner() {
  return (
    <div className="mx-8">
      <div className="flex justify-between mt-24 gomint">
        <Fade direction={"bottomToTop"} duration={1}>
          <h1 className="title mr-4">ALGOMINT TOKEN GOMINT NOW LIVE</h1>
        </Fade>
        <Fade direction={"bottomToTop"} duration={1}>
          <p>March 30, 2022</p>
        </Fade>
      </div>
      <Fade direction={"bottomToTop"} duration={1}>
        <p className="mt-8 text-justify">
          Today’s token launch marks a significant step in Algomint’s evolution.
          The much anticipated{" "}
          <a className="link" href="https://www.algomint.io/">
            Algomint
          </a>{" "}
          governance token, goMINT, is the first Algorand-native three-way
          listing on{" "}
          <a className="link" href="https://www.pact.fi/">
            PACT
          </a>{" "}
          and{" "}
          <a className="link" href="https://tinyman.org/">
            {" "}
            Tinyman,
          </a>
          and on
          <a className="link" href="https://www.algorand.com/">
            Algorand.
          </a>{" "}
          Launched today at 9am AEDT on 30 Mar, 2022, the listing will also
          offer the top buyers chance to win 1 of 300 much anticipated{" "}
          <a className="link" href="https://www.blackbook.art/">
            Blackbook NFTs{" "}
          </a>
          from one of the world’s leading graffiti artists! As Algomint moves to
          be a decentralised smart contract platform, the goMINT token will
          become critical to governing the protocol.
        </p>
      </Fade>
    </div>
  );
}
