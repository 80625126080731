import React from "react";
import "./InvestorsToken.css";
import Fade from "../../animations/Fade";

export default function InvestorsTokenBanner() {
  return (
    <div className="mx-8">
      <Fade direction={"bottomToTop"} duration={1}>
        <h1 className="title my-8">Setting investors up for success</h1>
      </Fade>
      <Fade direction={"bottomToTop"} duration={1}>
        <p>
          Investors looking to take advantage of the early token price will need
          to connect their ALGO wallets with
          <a className="link" href="https://www.pact.fi/">
            PACT{" "}
          </a>
          or{" "}
          <a className="link" href="https://tinyman.org/">
            {" "}
            Tinyman.
          </a>{" "}
          Users will be able to use the “swap” feature on each platform to
          acquire the token. To make for an easier trading experience, we’ve
          created this instructional video to show users how to acquire the
          token.
        </p>
      </Fade>
      <Fade direction={"bottomToTop"} duration={1}>
        {/* <div className="mt-8 video-responsive">
          <iframe
            width="853"
            height="480"
            src="https://www.youtube.com/embed/uLynJEl08Rs"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </div> */}
      </Fade>
    </div>
  );
}
