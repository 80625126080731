import React from "react";
import "./TokenLive.css";
import ButtonTransparent from "../ButtonTransparent";
import Fade from "../../animations/Fade";

export default function TokenLiveBanner() {
  return (
    <div className="live  w-full">
      <div className="token-items flex justify-between mt-20">
        <Fade direction={"bottomToTop"} duration={1}>
          <p>
            goMINT token <br />
            NOW LIVE
          </p>
        </Fade>
        <div className="flex space-x-2">
          <Fade direction={"bottomToTop"} duration={1}>
            <ButtonTransparent
              title={"Tokenomics"}
              url={"documents/Algomint_Tokenomics.pdf"}
              displayIcon={true}
              showBorder={true}
              styles={"px-2"}
            />
          </Fade>
          <Fade direction={"bottomToTop"} duration={1}>
            <ButtonTransparent
              title={"Litepaper"}
              url={"documents/Algomint_Litepaper.pdf"}
              displayIcon={true}
              showBorder={true}
              styles={"px-2"}
            />
          </Fade>
        </div>
      </div>
      <Fade direction={"leftToRight"} duration={1}>
        <h1 className="heading titleStyle my-40">
          Algomint Token <br /> Now Live
        </h1>
      </Fade>
    </div>
  );
}
