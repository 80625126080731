export const tokenParagraphItems = [
  {
    title: "Price maintenance, risk mitigation and investor security",
    content: `
          
            <p>
              The goMINT token launch will include three equally distributed $1M liquidity pools across the
              three DEX’s of goMINT/ALGO. Approximately 2–3% of the token supply will be sold into these pools
              over 12 hours . To safeguard its investors against price fluctuations, Algomint will be manually 
              maintaining the price between 0.10 -0.15c, with robust bot contingencies across the 3 DEX’s. 
              Initially there won't be single sided government staking, it will be staking in the LP pairs 
              across some of the Algorand DeFi platforms, with announcements to be made on Algomint’s Twitter 
              account as joint liquidity incentive programs are formed.
              </p>

              <p style="margin-top: 24px" >
              As with all token purchases, investors need to be conscious of fake tokens and only opt in ASA
              441139422 in their ALGO wallets (Click <a style="border-bottom: 1px dotted"  onMouseOver="this.style.color='#1cffd6'" href="https://algomint-1.gitbook.io/algomint/mainnet-wallet-preparation">here</a> 
              for wallet preparation guide) as the genuine goMINT token. . The price will be maintained between 0.10 - 0.15c for the 
              initial 12 hours before opening up to free market price discovery. Investors are cautioned against
              paying extra during price fluctuation and it’s always better to buy as the price movement calms down.
              </p>`,
  },
  {
    title: "Algomint token utility",
    content: `<p>
                The key utility of goMINT will be for platform governance activities pertaining to voting on token
                listings, blockchain integrations, fees, treasury management, and where the protocol is heading from
                a functionality standpoint. In the future, extra incentives have been earmarked, such as reduced fees 
                through staking, eligibility for rewards through participation, including LP rewards pools.
              </p>`,
  },
  {
    title: "goMINT tokenomics - a low emissions schedule",
    content: `<p>
                Initially rewards and incentives will be fuelled from the Treasury allocation of goMINT tokens. 
                Once depleted, it’s expected that protocol fees will shift to the Buy-&-Make model for rewards
                and incentives, channeling initial fees back into the pools for building protocol owned liquidity. 
                With the treasury expected to last 5+yrs this will provide a significant runway allowing the treasury 
                to amass a sufficient LP position generating long term revenue and providing deep liquidity in the goMINT token.
              </p>
              
              <p style="margin-top: 24px" >
                With a total supply of 1 billion tokens, it’s estimated 6% will go into circulation in the first 
                month in the liquidity pools. Tracking on a linear emissions schedule, the plan is to release 2.5 %
                to the public sale, with 48.5 % being retained inside the treasury. To read the full details on 
                goMINT token distribution, access our document <a style="border-bottom: 1px dotted" onMouseOver="this.style.color='#1cffd6'" href="documents/Algomint_Tokenomics.pdf">here.</a> 
              </p>`,
  },
  {
    title: "Protocol owned liquidity",
    content: `<p>
                A portion of fees collected by the Algomint protocol will be converted into $goMINT and other base
                asset’s Liquidity Pool pairing inside one or multiple DEXes. Any fees collected in $goMINT will 
                remain in the treasury until they can be LP’d. This removes goMINT tokens from the market, creates
                deeper liquidity, and can generate long term revenue through Liquidity Pool trading fees.
              </p>`,
  },
  {
    title: "International Launch Times:",
    content: `<div>
    <p>To ensure equitable access for global investors to purchase upon token launch, a 12hr price maintenance 
    window will cover global time zones:</p>
      <p style="margin-top: 24px"> LA 4pm on 29 Mar, 2022. </p>
      
      <p style="margin-top: 24px"> NY 7pm on 29 Mar, 2022. </p>
      
      <p style="margin-top: 24px"> London 11pm on 29 Mar, 2022. </p>
      
      <p style="margin-top: 24px"> Singapore 7am on 30 Mar, 2022 </p>
      
      <p style="margin-top: 24px"> BRIS 9am AEDT on 30 Mar, 2022. </p>
      
      <p style="margin-top: 24px"> SYD 10am AEDT on 30 Mar, 2022. </p>
    </div>`,
  },
  {
    title: "About Algomint",
    content: `<p>
                Algomint is a cross-chain liquidity network, with a focus on maintaining liquid interoperability 
                while safely and securely optimising capital efficiency and cross chain transactions. goMINT is 
                the protocol's native governance token used to manage the Algomint DAO which also controls the DAO
                 treasury that is the recipient of fees generated on the platform. Users will stake their goMINT 
                 tokens to participate in governance and earn rewards.
              </p>`,
  },
];